import {navigate} from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import {Button, Text, tokens} from '@pleo-io/telescope'

import image404 from '@/assets/404.png'

import {Layout} from '../components/layout'
import {SEO} from '../components/seo/seo'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    border-left: ${tokens.borderStaticLoud};
`

const Image = styled.img`
    max-width: 300px;
`

const NotFoundPage = (props: any) => (
    <Layout {...props} is404Page>
        <Wrapper>
            <SEO title="404: Not found" />
            <Image alt="Not found illustrations" src={image404} />
            <Text variant="4xlarge-accent" as="h2" weight="bold" space={20}>
                Something is fishy!
            </Text>
            <Text variant="xlarge-accent" as="p" space={32} css={{maxWidth: '50ch'}}>
                This page may have been moved or deleted. Please double check the address or browse
                back to our home page.
            </Text>
            <Button variant="primary" onClick={() => navigate('/')}>
                Go back home
            </Button>
        </Wrapper>
    </Layout>
)

export default NotFoundPage
